import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchGeoJson = createAsyncThunk('geoJson/fetchGeoJson', async () => {
    const response = await fetch('/data/zimbabwe_districts.geojson');
    const data = await response.json();
    return data;
});

const geoJsonSlice = createSlice({
    name: 'geoJson',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGeoJson.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGeoJson.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchGeoJson.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default geoJsonSlice.reducer;
