import { createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../config/config';

export const fetchDistrictData = createAsyncThunk('district/fetchDistrictData', async ({ districtId, districtName }) => {
    const response = await fetch(`${config.baseUri}id=${districtId}`);
    const data = await response.json();
    if (data.error) {
        throw new Error(data.error);
    }
    return { ...data, districtName };
});

export const submitCrimeReport = createAsyncThunk('district/submitCrimeReport', async (details) => {
    console.log(`Posting details: ${JSON.stringify(details)}`);
    const response = await fetch(`${config.baseUri}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({id: details.id, name: details.Name, category: details.category, Details: details.Details })
    });
    const data = await response.json();
    if (data.error) {
        throw new Error(data.error);
    }
    return data;
});
