import { configureStore } from '@reduxjs/toolkit';
import geoJsonReducer from '../reducers/geoJsonReducer';
import districtReducer from '../reducers/districtsReducer';

const store = configureStore({
    reducer: {
        geoJson: geoJsonReducer,
        district: districtReducer,
    },
    devTools: true
});

export default store;
