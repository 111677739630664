import { createSlice } from '@reduxjs/toolkit';
import { fetchDistrictData, submitCrimeReport } from '../actions/districtActions';

const districtSlice = createSlice({
    name: 'district',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDistrictData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDistrictData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchDistrictData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(submitCrimeReport.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(submitCrimeReport.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(submitCrimeReport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default districtSlice.reducer;
