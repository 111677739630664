import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Puff } from 'react-loader-spinner';
import { fetchGeoJson } from '../redux/reducers/geoJsonReducer';
import 'bootstrap/dist/css/bootstrap.min.css';

const LandingPage = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchGeoJson());
            setLoading(false);
            navigate('/map');
        };

        fetchData().catch(error => {
            console.error('Error fetching the GeoJSON data:', error);
            setLoading(false);
        });
    }, [dispatch, navigate]);

    return (
        <div className="loader-container" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Puff color="#00BFFF" height={100} width={100} />
            <p>Loading Map coordinates...</p>
        </div>
    );
};

export default LandingPage;
