import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Typography,
} from '@mui/material';
import { useTable, useFilters, useGlobalFilter } from 'react-table';
import { useDispatch } from 'react-redux';
import { submitCrimeReport } from '../redux/actions/districtActions';
import { styled } from '@mui/material/styles';

const DialogStyled = styled(Dialog)(({ theme }) => ({
    borderRadius: '8px',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
}));

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    textAlign: 'center',
}));

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1.5),
    fontWeight: 'bold',
}));

const FormControlStyled = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(2, 0),
}));

const TableStyled = styled('table')(({ theme }) => ({
    marginTop: theme.spacing(2),
    width: '100%',
    borderCollapse: 'collapse',
}));

const TableHeaderStyled = styled('thead')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    fontWeight: 'bold',
}));

const TableCellStyled = styled('td')(({ theme }) => ({
    padding: theme.spacing(1),
    borderBottom: '1px solid #ccc',
}));

const crimeCategories = [
    "Abduction", "Torture", "Murder (Dead)", "Jailed", "Forced Disappearance",
    "Threatened", "Falsely Accused", "Assets Confiscated", "Physically Assaulted",
    "Sexually Abused", "Economic Hardships", "Displacement"
];

const perpetrators = [
    "Government (Army)", "Government (Police)", "Government (Intelligence)",
    "Government (Support Unit)", "Political Party (Zanu PF)", "Political Party (ZAPU)",
    "Political Party (MDC)", "Political Party CCC", "Political Party (Other)", "Unknown"
];

const genders = ["Male", "Female", "Not Specified"];

const CrimeModal = ({ show, handleClose, districtName, districtType, loading, error, districtData, districtId }) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showReportForm, setShowReportForm] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [detailsData, setDetailsData] = useState({});
    const [formData, setFormData] = useState({
        category: crimeCategories[0],
        age: 1,
        gender: "Not Specified",
        perpetrator: perpetrators[0],
        placeOfIncident: "",
        ward: "",
        nameOfVictim: "",
        hideName: true,
        detailsOfIncident: "",
        dateOfIncident: ""
    });
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (districtData && districtData.Cases) {
            setSelectedCategory(districtData.Cases[0].Category);
        } else {
            setSelectedCategory('');
            setFilteredData([]);
        }
    }, [districtData]);

    useEffect(() => {
        if (districtData && districtData.Cases) {
            const categoryData = districtData.Cases.find(caseData => caseData.Category === selectedCategory);
            setFilteredData(categoryData ? categoryData.Details : []);
        } else {
            setFilteredData([]);
        }
    }, [selectedCategory, districtData]);

    const validateForm = () => {
        const errors = {};
        if (!formData.category) errors.category = "Category is required";
        if (!formData.age || formData.age < 1 || formData.age > 100) errors.age = "Valid age is required";
        if (!formData.gender) errors.gender = "Gender is required";
        if (!formData.perpetrator) errors.perpetrator = "Perpetrator is required";
        if (!formData.placeOfIncident) errors.placeOfIncident = "Place of incident is required";
        if (!formData.ward) errors.ward = "Ward is required";
        if (!formData.nameOfVictim) errors.nameOfVictim = "Name of victim is required";
        if (!formData.detailsOfIncident) errors.detailsOfIncident = "Details of incident are required";
        if (!formData.dateOfIncident) errors.dateOfIncident = "Date of incident is required";
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleFormChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleFormSubmit = () => {
        if (validateForm()) {
            const summary = `
                Category: ${formData.category}\n
                Age: ${formData.age}\n
                Gender: ${formData.gender}\n
                Perpetrator: ${formData.perpetrator}\n
                Place Of Incident: ${formData.placeOfIncident}\n
                Ward: ${formData.ward}\n
                Name Of Victim: ${formData.nameOfVictim}\n
                Hide Name: ${formData.hideName ? 'Yes' : 'No'}\n
                Date Of Incident: ${formData.dateOfIncident}\n
                Details Of Incident: ${formData.detailsOfIncident}
            `;
            const confirmSubmit = window.confirm(`Confirm details:\n${summary}`);
            if (confirmSubmit) {
                const reportData = {
                    id: sessionStorage.getItem("currentDistrict"),
                    Name: districtName,
                    category: formData.category,
                    Details: {
                        Age: formData.age,
                        Gender: formData.gender,
                        Perpetrator: formData.perpetrator,
                        "Place Of Incident": formData.placeOfIncident,
                        Ward: formData.ward,
                        DateOfIncident: formData.dateOfIncident,
                        "Name of Victim": formData.nameOfVictim,
                        HideName: formData.hideName,
                        "Details of Incident": formData.detailsOfIncident
                    }
                };
                dispatch(submitCrimeReport(reportData));
                setShowReportForm(false);
            }
        }
    };

    const columns = React.useMemo(() => [
        {
            Header: 'Perpetrator',
            accessor: 'Perpetrator',
        },
        {
            Header: 'Place Of Incident',
            accessor: 'Place Of Incident',
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <Button variant="text" onClick={() => {
                    setDetailsData(row.original);
                    setShowDetails(true);
                }}>
                    View Details
                </Button>
            ),
        },
    ], []);

    const data = React.useMemo(() => filteredData, [filteredData]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        state: { globalFilter }
    } = useTable({ columns, data }, useFilters, useGlobalFilter);

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        setFilteredData([]);  // Clear the table when category changes
    };

    const resetModalState = () => {
        setShowReportForm(false);
        setFormData({
            category: crimeCategories[0],
            age: 1,
            gender: "Not Specified",
            perpetrator: perpetrators[0],
            placeOfIncident: "",
            ward: "",
            nameOfVictim: "",
            hideName: true,
            detailsOfIncident: "",
            dateOfIncident: ""
        });
        setFormErrors({});
    };

    const handleModalClose = () => {
        resetModalState();
        handleClose();
    };

    const handleDetailsClose = () => {
        setShowDetails(false); // Close the details view
    };

    return (
        <>
            <Dialog open={show} onClose={handleModalClose} fullWidth maxWidth="md" classes={{ paper: DialogStyled }}>
                <DialogTitleStyled>
                    {districtName ? `${districtName} (${districtType})` : 'District Details'}
                </DialogTitleStyled>
                <DialogContentStyled>
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </div>
                    ) : error ? (
                        <div>{error}</div>
                    ) : (
                        <>
                            {!showReportForm && !showDetails && (
                                <>
                                    {districtData && districtData.Cases ? (
                                        <>
                                            <FormControlStyled fullWidth margin="normal">
                                                <InputLabel id="crimeTypeLabel">Type of Crime</InputLabel>
                                                <Select
                                                    labelId="crimeTypeLabel"
                                                    value={selectedCategory}
                                                    onChange={handleCategoryChange}
                                                >
                                                    {districtData.Cases.map((caseData, index) => (
                                                        <MenuItem key={index} value={caseData.Category}>{caseData.Category}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControlStyled>
                                            <FormControlStyled fullWidth margin="normal">
                                                <TextField
                                                    label="Search"
                                                    value={globalFilter || ''}
                                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                                    placeholder="Search in table..."
                                                />
                                            </FormControlStyled>
                                            <TableStyled {...getTableProps()}>
                                                <TableHeaderStyled>
                                                    {headerGroups.map(headerGroup => (
                                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                                            {headerGroup.headers.map(column => (
                                                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </TableHeaderStyled>
                                                <tbody {...getTableBodyProps()}>
                                                    {rows.map(row => {
                                                        prepareRow(row);
                                                        return (
                                                            <tr {...row.getRowProps()}>
                                                                {row.cells.map(cell => (
                                                                    <TableCellStyled {...cell.getCellProps()}>{cell.render('Cell')}</TableCellStyled>
                                                                ))}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </TableStyled>
                                        </>
                                    ) : (
                                        <div>No cases reported for this district.</div>
                                    )}
                                </>
                            )}
                            {showDetails && (
                                <div>
                                    <Typography variant="h6">Category: {detailsData.Category}</Typography>
                                    <Typography variant="body1"><strong>Age:</strong> {detailsData.Age}</Typography>
                                    <Typography variant="body1"><strong>Gender:</strong> {detailsData.Gender}</Typography>
                                    <Typography variant="body1"><strong>Perpetrator:</strong> {detailsData.Perpetrator}</Typography>
                                    <Typography variant="body1"><strong>Place Of Incident:</strong> {detailsData['Place Of Incident']}</Typography>
                                    <Typography variant="body1"><strong>Ward:</strong> {detailsData.Ward}</Typography>
                                    <Typography variant="body1"><strong>Name Of Victim:</strong> {detailsData['Name of Victim']}</Typography>
                                    <Typography variant="body1"><strong>Date Of Incident:</strong> {detailsData.DateOfIncident}</Typography>
                                    <Typography variant="body1"><strong>Details Of Incident:</strong> {detailsData['Details of Incident']}</Typography>
                                    <Button variant="outlined" onClick={handleDetailsClose}>
                                        Close
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </DialogContentStyled>
                <DialogActionsStyled>
                    <ButtonStyled variant="contained" color="primary" onClick={() => setShowReportForm(true)}>
                        Report Crime
                    </ButtonStyled>
                    <ButtonStyled variant="outlined" onClick={handleModalClose}>
                        Close
                    </ButtonStyled>
                </DialogActionsStyled>
            </Dialog>

            <Dialog open={showReportForm} onClose={() => setShowReportForm(false)} fullWidth maxWidth="md" classes={{ paper: DialogStyled }}>
                <DialogTitleStyled>Report Crime</DialogTitleStyled>
                <DialogContentStyled>
                    <FormControlStyled fullWidth margin="normal">
                        <InputLabel id="crimeCategoryLabel">Category</InputLabel>
                        <Select labelId="crimeCategoryLabel" name="category" value={formData.category} onChange={handleFormChange} error={!!formErrors.category}>
                            {crimeCategories.map((category, index) => (
                                <MenuItem key={index} value={category}>{category}</MenuItem>
                            ))}
                        </Select>
                    </FormControlStyled>
                    <TextField
                        label="Age"
                        type="number"
                        name="age"
                        value={formData.age}
                        onChange={handleFormChange}
                        error={!!formErrors.age}
                        helperText={formErrors.age}
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 1, max: 100 }}
                    />
                    <FormControlStyled fullWidth margin="normal">
                        <InputLabel id="genderLabel">Gender</InputLabel>
                        <Select labelId="genderLabel" name="gender" value={formData.gender} onChange={handleFormChange} error={!!formErrors.gender}>
                            {genders.map((gender, index) => (
                                <MenuItem key={index} value={gender}>{gender}</MenuItem>
                            ))}
                        </Select>
                    </FormControlStyled>
                    <FormControlStyled fullWidth margin="normal">
                        <InputLabel id="perpetratorLabel">Perpetrator</InputLabel>
                        <Select labelId="perpetratorLabel" name="perpetrator" value={formData.perpetrator} onChange={handleFormChange} error={!!formErrors.perpetrator}>
                            {perpetrators.map((perpetrator, index) => (
                                <MenuItem key={index} value={perpetrator}>{perpetrator}</MenuItem>
                            ))}
                        </Select>
                    </FormControlStyled>
                    <TextField
                        label="Place of Incident"
                        name="placeOfIncident"
                        value={formData.placeOfIncident}
                        onChange={handleFormChange}
                        error={!!formErrors.placeOfIncident}
                        helperText={formErrors.placeOfIncident}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Ward"
                        name="ward"
                        value={formData.ward}
                        onChange={handleFormChange}
                        error={!!formErrors.ward}
                        helperText={formErrors.ward}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Name of Victim"
                        name="nameOfVictim"
                        value={formData.nameOfVictim}
                        onChange={handleFormChange}
                        error={!!formErrors.nameOfVictim}
                        helperText={formErrors.nameOfVictim}
                        fullWidth
                        margin="normal"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.hideName}
                                onChange={handleFormChange}
                                name="hideName"
                            />
                        }
                        label="Hide Name"
                    />
                    <TextField
                        label="Date of Incident"
                        type="date"
                        name="dateOfIncident"
                        value={formData.dateOfIncident}
                        onChange={handleFormChange}
                        error={!!formErrors.dateOfIncident}
                        helperText={formErrors.dateOfIncident}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: "1970-01-01" }}
                    />
                    <TextField
                        label="Details of Incident"
                        name="detailsOfIncident"
                        value={formData.detailsOfIncident}
                        onChange={handleFormChange}
                        error={!!formErrors.detailsOfIncident}
                        helperText={formErrors.detailsOfIncident}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        inputProps={{ maxLength: 2500 }}
                    />
                </DialogContentStyled>
                <DialogActionsStyled>
                    <ButtonStyled variant="contained" color="primary" onClick={handleFormSubmit}>
                        Submit
                    </ButtonStyled>
                    <ButtonStyled variant="outlined" onClick={() => setShowReportForm(false)}>
                        Cancel
                    </ButtonStyled>
                </DialogActionsStyled>
            </Dialog>
        </>
    );
};

export default CrimeModal;